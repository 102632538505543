@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html,
body {
  overflow-x: hidden;
  overscroll-behavior-y: contain;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  width: 100%;
  line-height: 1.5;
  color: #262626;
  font-family: 'Inter', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background: #75757514; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  color: #313131;
  margin-bottom: 1rem;
  /* color: 'rgba(8, 25, 51, 0.85);' */
  /* color: #1f3f5b; */
  /* color: #1f5b50; */
}

p,
span,
.ant-input {
  font-family: 'Inter', sans-serif;
}

button:focus {
  outline: 0;
}

/* Override default AntD styling */
.ant-select-clear {
  margin-bottom: 0rem;
  font-size: 1.5rem;
  top: 25%;
  width: initial;
  height: initial;
  margin-top: 0;
  opacity: 1;
}

/* Override default message position */
.ant-message {
  bottom: 10rem;
  top: initial;
  left: 55vw;
  width: auto;
}

.ant-select-item-option-content {
  white-space: initial;
}

@media (min-width: 0px) and (max-width: 479px) {
  .ant-message {
    left: 0;
  }
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
  margin-left: 1rem;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
