.progress-bar {
  position: relative;
  width: 350px;
  height: 5px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.filler {
  opacity: 0.65;
  background: #48a54c; /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  */
  /* background: linear-gradient(
    to right,
    #78ffd6,
    #a8ff78
  ); */

  height: 100%;
  border-radius: inherit;
  transition: width 0.3s ease-in;
}

@media (min-width: 0px) and (max-width: 479px) {
  .progress-bar {
    display: flex;
    width: 90%;
    margin-bottom: 0px;
  }
}
